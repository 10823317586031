// Here you can add other styles
div {
    &.ReactTags__tags {
      position: relative;
    }
  
    &.ReactTags__tagInput {
      width: 200px;
      border-radius: 2px;
      display: inline-block;
  
      input.ReactTags__tagInputField {
        height: 31px;
        margin: 0;
        font-size: 12px;
        width: 100%;
        border: 1px solid #eee;
        padding: 0 4px;
  
        &:focus {
          height: 35px;
          margin: 0 5px;
          font-size: 12px;
          outline: unset;
          width: 100%;
          border: 1px solid #ffc205;
          padding: 0 7px;
          border-radius: 4px;
        }
      }
    }
  
    &.ReactTags__selected {
      span.ReactTags__tag {
        background: #ffc205;
        font-size: 0.875rem;
        display: inline-block;
        padding: 5px;
        margin: 5px;
        cursor: move;
        border-radius: 4px;
      }
  
      a.ReactTags__remove {
        color: #aaa;
        margin-left: 5px;
        cursor: pointer;
        font-weight: 800;
      }
    }
  
    &.ReactTags__suggestions {
      position: absolute;
  
      ul {
        list-style-type: none;
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
        background: white;
        width: 200px;
      }
  
      li {
        border-bottom: 1px solid #ddd;
        padding: 5px 10px;
        margin: 0;
  
        mark {
          text-decoration: underline;
          background: none;
          font-weight: 600;
        }
      }
  
      ul li.ReactTags__activeSuggestion {
        background: #b7cfe0;
        cursor: pointer;
      }
    }
  }
.user-information-tb {
  tr th:first-child {
    width: 140px;
  }
}